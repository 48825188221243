/*Bootstrap core imports - adjust for your case*/
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";

// Bootstrap Icons code
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.2/font/bootstrap-icons.min.css");

$custom-colors: (
  "subtleprimary": #cfe2ff,
  "light-blue": #edf5ff,
  "facebook": #385499,
  "google": #ffffff,
  "light-gray": #f2f3f5,
);

$theme-colors: map-merge($theme-colors, $custom-colors);

// import bootstrap whole sass lib (incorporates all above redefined vars & values)
@import "../../node_modules/bootstrap/scss/bootstrap";

@import url("https://fonts.googleapis.com/css2?family=Chilanka&family=Coming+Soon&family=Kalam:wght@300;400;700&family=Neucha&family=Patrick+Hand&display=swap");
// @import "~react-image-gallery/styles/scss/image-gallery.scss";
@import "../../node_modules/react-image-gallery/styles/scss/image-gallery.scss";
// $primary: #6673e3;

// .bg classes not automatically generated. As an example, manually add
// .bg-custom-color

$custom-color: #ecedf1; // Your custom color

.light-bg {
  background-color: #f8f9fa; /* This is the default light background color used by Bootstrap */
}

.custom-tooltip {
  --bs-tooltip-bg: var(--bs-primary);
}
.dropdown-toggle {
  outline: 0;
}

.btn-toggle {
  padding: 0.25rem 0.5rem;
  font-weight: 600;
  color: var(--bs-emphasis-color);
  background-color: transparent;
}
.btn-toggle:hover,
.btn-toggle:focus {
  color: rgba(var(--bs-emphasis-color-rgb), 0.85);
  background-color: var(--bs-tertiary-bg);
}

.btn-toggle::after {
  width: 1.25em;
  line-height: 0;
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
  transition: transform 0.35s ease;
  transform-origin: 0.5em 50%;
}

[data-bs-theme="dark"] .btn-toggle::after {
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%28255,255,255,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
}

.btn-toggle[aria-expanded="true"] {
  color: rgba(var(--bs-emphasis-color-rgb), 0.85);
}
.btn-toggle[aria-expanded="true"]::after {
  transform: rotate(90deg);
}
.btn-toggle-nav a {
  padding: 0.1875rem 0.5rem;
  margin-top: 0.125rem;
  margin-left: 1.25rem;
}
.btn-toggle-nav a:hover,
.btn-toggle-nav a:focus {
  background-color: var(--bs-tertiary-bg);
}

.scrollarea {
  overflow-y: auto;
}

.list-group {
  width: 100%;
  // max-width: 460px;
  // margin-inline: 1.5rem;
}

.form-check-input:checked + .form-checked-content {
  opacity: 0.5;
}

.form-check-input-placeholder {
  border-style: dashed;
}
[contenteditable]:focus {
  outline: 0;
}

.list-group-checkable .list-group-item {
  cursor: pointer;
}
.list-group-item-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
.list-group-item-check:hover + .list-group-item {
  background-color: var(--bs-secondary-bg);
}
.list-group-item-check:checked + .list-group-item {
  color: #fff;
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}
.list-group-item-check[disabled] + .list-group-item,
.list-group-item-check:disabled + .list-group-item {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}

.list-group-radio .list-group-item {
  cursor: pointer;
  border-radius: 0.5rem;
}
.list-group-radio .form-check-input {
  z-index: 2;
  margin-top: -0.5em;
}
.list-group-radio .list-group-item:hover,
.list-group-radio .list-group-item:focus {
  background-color: var(--bs-secondary-bg);
}

.list-group-radio .form-check-input:checked + .list-group-item {
  background-color: var(--bs-body);
  border-color: var(--bs-primary);
  box-shadow: 0 0 0 2px var(--bs-primary);
}
.list-group-radio .form-check-input[disabled] + .list-group-item,
.list-group-radio .form-check-input:disabled + .list-group-item {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}
.marketplace-jumbotron-cover {
  background-image: url("https://res.cloudinary.com/dszd4owdm/image/upload/v1707879231/Untitled_design_2_cjfkxr.jpg");
  background-size: cover;
  background-position: center;
  color: #fff; /* You may need to adjust text color for better visibility */
}
.about-jumbotron-cover {
  background-image: url("https://res.cloudinary.com/dszd4owdm/image/upload/v1707878695/Untitled_design_1_v3ajlz.jpg");
  background-size: cover;
  background-position: center;
  color: #fff; /* You may need to adjust text color for better visibility */
}
@media print {
  /* Print-specific styles go here */
  .hide-during-print {
    display: none;
  }
  .shrink-image-print {
    max-width: 30% !important;
    height: auto !important;
  }
  // body {
  //   transform: scale(0.85); /* Adjust the scale factor as needed */
  //   transform-origin: top left;
  // }
  // body {
  //   filter: grayscale(100%);
  // }
}
//Cartoon Fonts
// $font-family-base: "Chilanka";
// $font-family-base: "Coming Soon"; //Like this one
// $font-family-base: "Neucha";
// $font-family-base: "Kalam";
// $font-family-base: "Patrick Hand";

@import "../../node_modules/bootstrap/scss/bootstrap";
